import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppRoutesEnum } from '@app/routes';
import { ModalRoutesEnum } from '@app/shared/modals-routed';
import { validateEmail } from '@app/shared/validators/email.validator';
import { ErrorInterface, LoginRequestInterface, NewModal } from 'outshared-lib';

@Component({
    selector: 'ins-login',
    templateUrl: './login.component.html',
})
export class LoginComponent {
    @Input() loading: boolean = false;
    @Input() errors: ErrorInterface[];
    @Output() readonly login: EventEmitter<LoginRequestInterface> = new EventEmitter();

    public credentials = new UntypedFormGroup({
        email: new UntypedFormControl('', [Validators.required, validateEmail]),
        password: new UntypedFormControl('', [Validators.required]),
        stayLoggedIn: new UntypedFormControl(true),
    });

    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;

    constructor(@Inject(DOCUMENT) private document: Document, private modalService: NewModal) {}

    /**
     *  Chrome autofill bugfix (https://github.com/angular/components/issues/3414)
     *  If Chrome uses autofill to set username and password,
     *  password is not set directly (only after user interaction).
     *  The emailChanged() function sets password if email is set which fixes the
     *  Chrome autofill bug.
     */
    public emailChanged($event): void {
        const email = $event.target as HTMLInputElement;
        const password = this.document.getElementById('password') as HTMLInputElement;

        if (email?.value) {
            this.email.setValue(email.value.trim());
        }
        if (password?.value) {
            this.password.setValue(password.value);
        }
    }

    public onSubmit(): void {
        if (this.credentials.invalid) {
            this.credentials.markAllAsTouched();
            return;
        }

        this.login.emit(this.credentials.value);
    }

    public get email(): AbstractControl {
        return this.credentials.get('email');
    }

    public get password(): AbstractControl {
        return this.credentials.get('password');
    }

    public async showAccountRecoveryModal(): Promise<void> {
        const { AccountRecoveryComponent } = await import('@authentication/feature-account-recovery/account-recovery');
        this.modalService.open(AccountRecoveryComponent, { data: { credentials: this.credentials.getRawValue() } });
    }
}
