import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import {
    AuthenticationActionService,
    AuthenticationDataService,
    AuthenticationErrorInterface,
    AuthenticationStatusEnum,
    LoginRequestInterface,
    NewModal,
} from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-login-container',
    template: ` <ins-login [loading]="loading$ | async" [errors]="errors" (login)="onLogin($event)"> </ins-login> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainer {
    @Output() public readonly afterLogin: EventEmitter<void> = new EventEmitter<void>();

    public loading$: Observable<boolean> = this.authenticationDataService.loginPending$();
    public errors: AuthenticationErrorInterface[] = [];
    private credentials: LoginRequestInterface;

    constructor(
        private authenticationActionService: AuthenticationActionService,
        private authenticationDataService: AuthenticationDataService,
        private newModal: NewModal
    ) {}

    public onLogin(credentials: LoginRequestInterface): void {
        this.credentials = credentials;
        this.authenticationActionService.login$(credentials).subscribe(
            () => this.afterLogin.emit(),
            (errors: AuthenticationErrorInterface[]) => this.handleErrors(errors)
        );
        return;
    }

    private handleErrors(errors: AuthenticationErrorInterface[]): void {
        const specialStatus: AuthenticationStatusEnum[] = [
            AuthenticationStatusEnum.TemporaryPassword,
            AuthenticationStatusEnum.Blocked,
            AuthenticationStatusEnum.GeneratedAccount,
        ];

        const statusError = errors.find((error) => specialStatus.includes(error.status));

        if (statusError) {
            this.handleStatus(statusError.status);
        } else {
            this.errors = errors;
        }
        return;
    }

    private async handleStatus(statusCode: AuthenticationStatusEnum): Promise<void> {
        switch (statusCode) {
            case AuthenticationStatusEnum.TemporaryPassword: {
                const { TemporaryPasswordComponent } = await import(
                    '@authentication/feature-account-recovery/temporary-password'
                );
                this.newModal.open(TemporaryPasswordComponent);
                break;
            }
            case AuthenticationStatusEnum.Blocked: {
                const { UnblockAccountComponent } = await import(
                    '@authentication/feature-account-recovery/unblock-account'
                );
                this.newModal.open(UnblockAccountComponent, { data: { credentials: this.credentials } });
                break;
            }
            case AuthenticationStatusEnum.GeneratedAccount: {
                const { ValidateGeneratedAccountComponent } = await import(
                    '@authentication/feature-account-recovery/validate-generated-account'
                );
                this.newModal.open(ValidateGeneratedAccountComponent);
                break;
            }
        }
    }
}
