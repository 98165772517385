import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginContainer } from '@app/login/login/login.container';
import { ShowPasswordDirective } from '@app/shared/directives/show-password.directive';
import {
    WucBalloonModule,
    WucButtonModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucIconModule,
    WucIconUiModule,
    WucInputCheckboxModule,
    WucInputPasswordModule,
    WucInputTextModule,
    WucLinkModule,
    WucListItemModule,
    WucListModule,
} from '@inshared/website-ui-components';
import { OutAccountRecoveryModule } from 'outshared-lib';
import { LoginComponent } from './login.component';

/** @deprecated use LoginModule from libs */
@NgModule({
    declarations: [LoginContainer, LoginComponent, ShowPasswordDirective],
    exports: [LoginContainer, LoginComponent],
    imports: [
        CommonModule,
        RouterModule,
        OutAccountRecoveryModule,
        ReactiveFormsModule,
        WucInputPasswordModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputTextModule,
        WucFormErrorModule,
        WucInputCheckboxModule,
        WucButtonModule,
        WucLinkModule,
        WucIconModule,
        WucBalloonModule,
        WucListModule,
        WucListItemModule,
        WucIconUiModule,
    ],
})
export class LoginModule {}
