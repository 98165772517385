<form [formGroup]="credentials" (ngSubmit)="onSubmit()">
    <wuc-form-field>
        <wuc-form-field-label>E-mailadres:</wuc-form-field-label>
        <wuc-input-text
            id="email"
            type="email"
            name="email"
            formControlName="email"
            placeholder="E-mailadres"
            autocomplete="username"
            e2e="email_address"
            (change)="emailChanged($event)"
        ></wuc-input-text>
        <wuc-form-error *ngIf="email.touched && email.hasError('required')">
            Oeps! Vult u uw e-mailadres nog even in?
        </wuc-form-error>
        <wuc-form-error *ngIf="email.touched && email.hasError('email')">
            Helaas! Het e-mailadres is niet goed ingevuld.
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field>
        <wuc-form-field-label>Wachtwoord</wuc-form-field-label>
        <wuc-input-password
            formControlName="password"
            id="password"
            placeholder="Wachtwoord"
            autocomplete="current-password"
            e2e="password"
            [hideHint]="true"
        ></wuc-input-password>
        <wuc-form-error *ngIf="password.touched && password.hasError('required')">
            Oeps! Vult u uw wachtwoord nog even in?
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field hasNoState="true">
        <wuc-form-field-label></wuc-form-field-label>
        <wuc-input-checkbox formControlName="stayLoggedIn" name="checkbox" id="stay-logged-in">
            Ingelogd blijven
        </wuc-input-checkbox>
    </wuc-form-field>

    <wuc-form-field hasNoState="true">
        <wuc-form-field-label></wuc-form-field-label>
        <wuc-button type="primary" [isSubmitButton]="true" [isFullWidth]="true" [disabled]="loading" e2e="login_submit">
            Inloggen
        </wuc-button>
    </wuc-form-field>
    <wuc-form-field hasNoState="true">
        <wuc-form-field-label></wuc-form-field-label>
        <wuc-link type="primary" (click)="showAccountRecoveryModal()" e2e="forgot_password_link">
            Wachtwoord vergeten?
        </wuc-link>
    </wuc-form-field>
</form>

<wuc-balloon backgroundStyle="secondary" padding="medium" [hasCloseIcon]="false" *ngIf="errors?.length">
    <wuc-list icon="cross">
        <ng-container *ngFor="let error of errors">
            <wuc-list-item>{{ error.message }}</wuc-list-item>
        </ng-container>
    </wuc-list>
</wuc-balloon>
